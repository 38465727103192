<template>
    <div class="bar-chart" id="barChart"></div>
</template>

<script>
    import * as echarts from 'echarts';

    export default {
        data() {
            return {
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(document.getElementById('barChart'))
        },
        methods: {
            setData(data) {
                let xAxisData = []
                let seriesData = []
                data.forEach(item => {
                    xAxisData.push(item.location||"其他")
                    seriesData.push(item.deviceCount)
                })
                this.chart.setOption({
                        title: {
                            subtext: '设备分布',
                            top: "5",
                            left: "20"
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xAxisData,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: [
                            {
                                name: '设备数量',
                                type: 'bar',
                                barWidth: '50%',
                                data: seriesData,
                                label: {
                                    show: true
                                }
                            }
                        ]
                    }
                )
            }
        }
    }
</script>

<style lang="less" scoped>
    .bar-chart {
        height: 300px;
        border: 1px solid #EEE;
        border-radius: 5px;
        background: #FDFDFD;
    }
</style>
