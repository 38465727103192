<template>
  <div style="width: 100%;height: 700px" id="map"></div>
</template>

<script>
  import * as echarts from 'echarts';
  import 'echarts-extension-amap';

  let myChart = null

  export default {
    mounted() {
      if(!myChart){
        myChart = echarts.init(document.getElementById('map'));
      }

    },
    destroyed() {
      if(myChart){
        myChart.clear()
        myChart=null
      }
    },
    methods:{
      update(onlineData,offlineData){
        myChart.setOption({
          title: {
            text: '',
            left: 'center',
            top:"15px",
            textStyle:{
              color:"#FFF"
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: (params,ticket)=>{
              let device = params.data
              let status = device.online?"在线":"离线"
              return `${device.nickname}<br/>设备状态：${status}<br/>设备编号：${device.mac}<br/>地址：${device.address}`
            }
          },
          // bmap: {
          //   center: [104.114129, 35.550339],
          //   zoom: 5,
          //   roam: true,
          //   mapStyle: bmapStyle
          // },
          amap: {
            // 3D模式，无论你使用的是1.x版本还是2.x版本，都建议开启此项以获得更好的渲染体验
            viewMode: '3D',
            // 高德地图支持的初始化地图配置
            // 高德地图初始中心经纬度
            center: [108.39, 39.9],
            // 高德地图初始缩放级别
            zoom: 5,
            // 是否开启resize
            resizeEnable: true,
            // 自定义地图样式主题
            mapStyle: 'amap://styles/blue',
            // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
            renderOnMoving: true,
            // ECharts 图层的 zIndex 默认 2000
            // 从 v1.9.0 起 此配置项已被弃用 请使用 `echartsLayerInteractive` 代替
            echartsLayerZIndex: 2019,
            // 设置 ECharts 图层是否可交互 默认为 true
            // 设置为 false 可实现高德地图自身图层交互
            // 此配置项从 v1.9.0 起开始支持
            echartsLayerInteractive: true,
            // 是否启用大数据模式 默认为 false
            // 此配置项从 v1.9.0 起开始支持
            largeMode: false
            // 说明：如果想要添加卫星、路网等图层
            // 暂时先不要使用layers配置，因为存在Bug
            // 建议使用amap.add的方式，使用方式参见最下方代码
          },
          series : [
            {
              name: '离线设备',
              type: 'scatter',
              coordinateSystem: 'amap',
              data: offlineData,
              symbolSize: 12,
              encode: {
                value: 2
              },
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              itemStyle: {
                color: "#FFF",
                shadowBlur: 2,
                shadowColor: "#000"
              },
              zlevel: 1
            },
            {
              name: '在线设备',
              type: 'effectScatter',
              coordinateSystem: 'amap',
              data: onlineData,
              symbolSize: 10,
              encode: {
                value: 2
              },
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                // number:3,
                scale:3.5,
                // period:3
              },
              hoverAnimation: true,
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              itemStyle: {
                color: "#27d85a",
                // shadowBlur: 10,
                // shadowColor: '#333'
              },
              zlevel: 2
            }
          ]
        });
      }
    }
  }
</script>

<style scoped>

</style>
